export type RegionId = 'americas' | 'asia' | 'australasia' | 'be' | 'dachcz' | 'europe-africa' | 'middle-east-india' | 'nl';

export const regions: { id: RegionId; name: string }[] = [
  { id: 'americas', name: 'Americas' },
  { id: 'asia', name: 'Asia' },
  { id: 'australasia', name: 'Australasia' },
  { id: 'be', name: 'Belgium' },
  { id: 'dachcz', name: 'DACH-CZ' },
  { id: 'europe-africa', name: 'Europe & Africa' },
  { id: 'middle-east-india', name: 'Middle East & India' },
  { id: 'nl', name: 'Netherlands' }
];

export const regionInstructions: { [key in RegionId]: { title: string; description: string; link: string; logoSrc: string; logoAlt: string }[] } = {
  americas: [
    {
      title: 'Instructions for Brunel CV Generator',
      description: 'Utilize the onboarding document to familiarize yourself with all the capabilities of HireAra. This comprehensive document provides a detailed overview of the features and functionalities of the platform, enabling you to navigate quickly and efficiently through all aspects of HireAra.',
      link: 'https://hireara.notion.site/Onboarding-Document-a97af84204fe428a8603db7c3fb4f2a7?pvs=4',
      logoSrc: '/images/HireAraLogoPurple.png',
      logoAlt: 'Powered by HireAra'
    },
    {
      title: 'Feedback Brunel CV Generator',
      description: 'Please fill out the form to provide feedback on the Brunel CV Generator. Your input is valuable and will help make the platform even more successful.',
      link: 'https://forms.office.com/Pages/ResponsePage.aspx?id=efKBNjtF8ESAJnIPTLOL2sDzhTY1rlRErNwz3sgkMRZUNERFVFBKTE9SRFFLWUlQRlQ3NkQyTUxBTy4u',
      logoSrc: '/images/brunel_logo.png',
      logoAlt: 'Powered by Brunel'
    }
  ],
  asia: [
    {
      title: 'Instructions for Instant CV Formatting (HireAra)',
      description: 'Utilize the onboarding document to familiarize yourself with all the capabilities of HireAra. This comprehensive document provides a detailed overview of the features and functionalities of the platform, enabling you to navigate quickly and efficiently through all aspects of HireAra.',
      link: 'https://hireara.notion.site/Onboarding-Document-a97af84204fe428a8603db7c3fb4f2a7?pvs=4',
      logoSrc: '/images/HireAraLogoPurple.png',
      logoAlt: 'Powered by HireAra'
    },
    {
      title: 'Feedback Instant CV Formatting (HireAra)',
      description: 'Please fill out the form to provide feedback on the Instant CV Formatting (HireAra). Your input is valuable and will help make the platform even more successful.',
      link: 'https://forms.office.com/Pages/ResponsePage.aspx?id=efKBNjtF8ESAJnIPTLOL2sDzhTY1rlRErNwz3sgkMRZUNERFVFBKTE9SRFFLWUlQRlQ3NkQyTUxBTy4u',
      logoSrc: '/images/brunel_logo.png',
      logoAlt: 'Powered by Brunel'
    }
  ],
  australasia: [
    {
      title: 'Instructions for Instant CV Formatting (HireAra)',
      description: 'Utilize the onboarding document to familiarize yourself with all the capabilities of HireAra. This comprehensive document provides a detailed overview of the features and functionalities of the platform, enabling you to navigate quickly and efficiently through all aspects of HireAra.',
      link: 'https://hireara.notion.site/Onboarding-Document-a97af84204fe428a8603db7c3fb4f2a7?pvs=4',
      logoSrc: '/images/HireAraLogoPurple.png',
      logoAlt: 'Powered by HireAra'
    },
    {
      title: 'Instructions for Recruiter Insider',
      description: 'Gather insights and reviews from your candidates and clients across the hiring process.',
      link: 'https://brunelcorporate.sharepoint.com/sites/reg-aus/SitePages/Customer-Experience.aspx#recruiter-insider',
      logoSrc: '/images/recruiter_insider.png',
      logoAlt: 'Powered by Recruiter Insider'
    },
    {
      title: 'Feedback Instant CV Formatting (HireAra)',
      description: 'Please fill out the form to provide feedback on the Instant CV Formatting (HireAra). Your input is valuable and will help make the platform even more successful.',
      link: 'https://forms.office.com/Pages/ResponsePage.aspx?id=efKBNjtF8ESAJnIPTLOL2sDzhTY1rlRErNwz3sgkMRZUNERFVFBKTE9SRFFLWUlQRlQ3NkQyTUxBTy4u',
      logoSrc: '/images/brunel_logo.png',
      logoAlt: 'Powered by Brunel'
    }
  ],
  be: [
    {
      title: 'Instructies Brunel CV-generator',
      description: 'Maak gebruik van het onboarding document om vertrouwd te raken met alle mogelijkheden van HireAra. Dit uitgebreide document biedt een gedetailleerd overzicht van de functies en functionaliteiten van het platform, waardoor je snel en efficiënt kunt navigeren door alle aspecten van HireAra.',
      link: 'https://hireara.notion.site/Onboarding-Document-a97af84204fe428a8603db7c3fb4f2a7?pvs=4',
      logoSrc: '/images/HireAraLogoPurple.png',
      logoAlt: 'Powered by HireAra'
    },
    {
      title: 'Feedback Brunel CV-generator',
      description: 'Vul alsjeblieft het formulier in om feedback te geven over de Brunel CV-generator. Jouw input is waardevol en zal helpen het platform nog succesvoller te maken.',
      link: 'https://forms.office.com/Pages/ResponsePage.aspx?id=efKBNjtF8ESAJnIPTLOL2sDzhTY1rlRErNwz3sgkMRZUNERFVFBKTE9SRFFLWUlQRlQ3NkQyTUxBTy4u',
      logoSrc: '/images/brunel_logo.png',
      logoAlt: 'Powered by Brunel'
    }
  ],
  dachcz: [
    {
      title: 'Instructions for Brunel CV Generator',
      description: 'Utilize the onboarding document to familiarize yourself with all the capabilities of HireAra. This comprehensive document provides a detailed overview of the features and functionalities of the platform, enabling you to navigate quickly and efficiently through all aspects of HireAra.',
      link: 'https://hireara.notion.site/Onboarding-Document-a97af84204fe428a8603db7c3fb4f2a7?pvs=4',
      logoSrc: '/images/HireAraLogoPurple.png',
      logoAlt: 'Powered by HireAra'
    },
    {
      title: 'Feedback Brunel CV Generator',
      description: 'Please fill out the form to provide feedback on the Brunel CV Generator. Your input is valuable and will help make the platform even more successful.',
      link: 'https://forms.office.com/Pages/ResponsePage.aspx?id=efKBNjtF8ESAJnIPTLOL2sDzhTY1rlRErNwz3sgkMRZUNERFVFBKTE9SRFFLWUlQRlQ3NkQyTUxBTy4u',
      logoSrc: '/images/brunel_logo.png',
      logoAlt: 'Powered by Brunel'
    }
  ],
  'europe-africa': [
    {
      title: 'Instructions for Brunel CV Generator',
      description: 'Utilize the onboarding document to familiarize yourself with all the capabilities of HireAra. This comprehensive document provides a detailed overview of the features and functionalities of the platform, enabling you to navigate quickly and efficiently through all aspects of HireAra.',
      link: 'https://hireara.notion.site/Onboarding-Document-a97af84204fe428a8603db7c3fb4f2a7?pvs=4',
      logoSrc: '/images/HireAraLogoPurple.png',
      logoAlt: 'Powered by HireAra'
    },
    {
      title: 'Feedback Brunel CV Generator',
      description: 'Please fill out the form to provide feedback on the Brunel CV Generator. Your input is valuable and will help make the platform even more successful.',
      link: 'https://forms.office.com/Pages/ResponsePage.aspx?id=efKBNjtF8ESAJnIPTLOL2sDzhTY1rlRErNwz3sgkMRZUNERFVFBKTE9SRFFLWUlQRlQ3NkQyTUxBTy4u',
      logoSrc: '/images/brunel_logo.png',
      logoAlt: 'Powered by Brunel'
    }
  ],
  'middle-east-india': [
    {
      title: 'Instructions for Brunel CV Generator',
      description: 'Utilize the onboarding document to familiarize yourself with all the capabilities of HireAra. This comprehensive document provides a detailed overview of the features and functionalities of the platform, enabling you to navigate quickly and efficiently through all aspects of HireAra.',
      link: 'https://hireara.notion.site/Onboarding-Document-a97af84204fe428a8603db7c3fb4f2a7?pvs=4',
      logoSrc: '/images/HireAraLogoPurple.png',
      logoAlt: 'Powered by HireAra'
    },
    {
      title: 'Feedback Brunel CV Generator',
      description: 'Please fill out the form to provide feedback on the Brunel CV Generator. Your input is valuable and will help make the platform even more successful.',
      link: 'https://forms.office.com/Pages/ResponsePage.aspx?id=efKBNjtF8ESAJnIPTLOL2sDzhTY1rlRErNwz3sgkMRZUNERFVFBKTE9SRFFLWUlQRlQ3NkQyTUxBTy4u',
      logoSrc: '/images/brunel_logo.png',
      logoAlt: 'Powered by Brunel'
    }
  ],
  nl: [
    {
      title: 'Instructies Brunel CV-generator',
      description: 'Maak gebruik van het onboarding document om vertrouwd te raken met alle mogelijkheden van HireAra. Dit uitgebreide document biedt een gedetailleerd overzicht van de functies en functionaliteiten van het platform, waardoor je snel en efficiënt kunt navigeren door alle aspecten van HireAra.',
      link: 'https://hireara.notion.site/Onboarding-Document-a97af84204fe428a8603db7c3fb4f2a7?pvs=4',
      logoSrc: '/images/HireAraLogoPurple.png',
      logoAlt: 'Powered by HireAra'
    },
    {
      title: 'Feedback Brunel CV-generator',
      description: 'Vul alsjeblieft het formulier in om feedback te geven over de Brunel CV-generator. Jouw input is waardevol en zal helpen het platform nog succesvoller te maken.',
      link: 'https://forms.office.com/Pages/ResponsePage.aspx?id=efKBNjtF8ESAJnIPTLOL2sDzhTY1rlRErNwz3sgkMRZUNERFVFBKTE9SRFFLWUlQRlQ3NkQyTUxBTy4u',
      logoSrc: '/images/brunel_logo.png',
      logoAlt: 'Powered by Brunel'
    }
  ],
};
  
  // Define region-specific content
  export const regionContent: Record<RegionId, { title: string; description: string; disclaimer: string; instructions: string; instructionstitle: string; }> = {
    americas: {
      title: 'Digital Services Portal',
      description: 'Unlock your full potential with our digital services portal and let us take care of the heavy lifting. Our innovative tools, CV Generator, and Client Proposal Generator, are designed to streamline your professional journey and handle repetitive tasks with ease. This allows you to focus on what really counts - the human interaction. With our efficient and innovative solutions, you can elevate your success and achieve more.',
      disclaimer: 'Disclaimer: The texts generated by these tools are intended to be used as an example only. It is always recommended to verify any text you create before usage.',
      instructionstitle: 'Instructions',
      instructions: 'To acces instructions for the different platform, simply click the button below to access the instructions. It’s an easy way to get the guidance you need quickly!'
    },
    asia: {
      title: 'Work smarter with Brunel’s digital tools',
      description: 'Harness the power of AI and a variety of other digital platforms to generate recruitment-related text and documents faster than ever before. Brunel has developed a range of digital tools to help write vacancies and prepare materials to use when presenting candidates.',
      disclaimer: 'DISCLAIMER: Technology makes life easier, but it isn’t perfect. Make sure that you check the accuracy/relevance of anything generated from these tools before you share or publish it.',
      instructionstitle: 'Instructions',
      instructions: 'To acces instructions for the different platform, simply click the button below to access the instructions. It’s an easy way to get the guidance you need quickly!'
    },
    australasia: {
      title: 'Work smarter with Brunel’s digital tools',
      description: 'Harness the power of AI and a variety of other digital platforms to generate recruitment-related text and documents faster than ever before. Brunel has developed a range of digital tools to help write vacancies, present candidates and gather feedback.',
      disclaimer: 'DISCLAIMER: Technology makes life easier, but it isn’t perfect. Make sure that you check the accuracy/relevance of anything generated from these tools before you share or publish it.',
      instructionstitle: 'Instructions',
      instructions: 'To acces instructions for the different platform, simply click the button below to access the instructions. It’s an easy way to get the guidance you need quickly!'
    },
    be: {
      title: 'Digital Services Portal',
      description: 'Ontgrendel je volledige potentieel met ons digitaal dienstenportaal en laat ons het zware werk voor je doen. Onze innovatieve tools, waaronder de My Brunel-app voor iOS en Android, CV-generator en Klant voorstel generator, zijn ontworpen om je professionele reis te stroomlijnen en repetitieve taken moeiteloos uit te voeren. Dit stelt je in staat om je te richten op wat echt belangrijk is - de menselijke interactie. Met onze efficiënte en innovatieve oplossingen kun je je succes naar een hoger niveau tillen en meer bereiken. Laat ons je helpen om "The Next Level" te bereiken!',
      disclaimer: 'Disclaimer: De teksten die deze tools genereren, zijn slechts voorbeelden. Controleer deze teksten goed voordat ze gebruikt worden.',
      instructionstitle: 'Instructions',
      instructions: 'To acces instructions for the different platform, simply click the button below to access the instructions. It’s an easy way to get the guidance you need quickly!'
    },
    dachcz: {
      title: 'Digital Services Portal',
      description: 'Unlock your full potential with our digital services portal and let us take care of the heavy lifting. Our innovative tools, including My Brunel app for iOS and Android, CV Generator, and Client Proposal Generator, are designed to streamline your professional journey and handle repetitive tasks with ease. This allows you to focus on what really counts - the human interaction. With our efficient and innovative solutions, you can elevate your success and achieve more. Let us help you reach "The Next Level”!',
      disclaimer: 'Disclaimer: The texts generated by these tools are intended to be used as an example only. It is always recommended to verify any text you create before usage.',
      instructionstitle: 'Instructions',
      instructions: 'To acces instructions for the different platform, simply click the button below to access the instructions. It’s an easy way to get the guidance you need quickly!'
    },
    'europe-africa': {
      title: 'Digital Services Portal',
      description: 'Unlock your full potential with our digital services portal and let us take care of the heavy lifting. Our innovative tools, CV Generator, and Client Proposal Generator, are designed to streamline your professional journey and handle repetitive tasks with ease. This allows you to focus on what really counts - the human interaction. With our efficient and innovative solutions, you can elevate your success and achieve more.',
      disclaimer: 'Disclaimer: The texts generated by these tools are intended to be used as an example only. It is always recommended to verify any text you create before usage.',
      instructionstitle: 'Instructions',
      instructions: 'To acces instructions for the different platform, simply click the button below to access the instructions. It’s an easy way to get the guidance you need quickly!'
    },
    'middle-east-india': {
      title: 'Digital Services Portal',
      description: 'Unlock your full potential with our digital services portal and let us take care of the heavy lifting. Our innovative tools, CV Generator, and Client Proposal Generator, are designed to streamline your professional journey and handle repetitive tasks with ease. This allows you to focus on what really counts - the human interaction. With our efficient and innovative solutions, you can elevate your success and achieve more.',
      disclaimer: 'Disclaimer: The texts generated by these tools are intended to be used as an example only. It is always recommended to verify any text you create before usage.',
      instructionstitle: 'Instructions',
      instructions: 'To acces instructions for the different platform, simply click the button below to access the instructions. It’s an easy way to get the guidance you need quickly!'
    },
    nl: {
      title: 'Digital Services Portal',
      description: 'Ontgrendel je volledige potentieel met ons digitaal dienstenportaal en laat ons het zware werk voor je doen. Onze innovatieve tools, waaronder de My Brunel-app voor iOS en Android, CV-generator en Klant voorstel generator, zijn ontworpen om je professionele reis te stroomlijnen en repetitieve taken moeiteloos uit te voeren. Dit stelt je in staat om je te richten op wat echt belangrijk is - de menselijke interactie. Met onze efficiënte en innovatieve oplossingen kun je je succes naar een hoger niveau tillen en meer bereiken. Laat ons je helpen om "The Next Level" te bereiken!',
      disclaimer: 'Disclaimer: De teksten die deze tools genereren, zijn slechts voorbeelden. Controleer deze teksten goed voordat ze gebruikt worden',
      instructionstitle: 'Instructions',
      instructions: 'To acces instructions for the different platform, simply click the button below to access the instructions. It’s an easy way to get the guidance you need quickly!'
    }
  };
  
  // Define apps and region-app mapping
  export const apps: Record<string, {
    title: Record<RegionId, string>;
    description: Record<RegionId, string>;
    link: string;
    imageSrc: string;
    showQRImg: string[];
    logoSrc: string;
    logoAlt: string;
    imageSrcQr?: string;
    category: string;
  }> = {
    cvGenerator: {
      title: {
        americas: 'Vacancy Text Generator',
        asia: 'Vacancy Text Generator',
        australasia: 'Vacancy Text Generator',
        be: 'Vacaturetekst generator',
        dachcz: 'Vacancy Text Generator',
        'europe-africa': 'Vacancy Text Generator',
        'middle-east-india': 'Vacancy Text Generator',
        nl: 'Vacancy Text Generator'
      },
      description: {
        americas: 'The AI Vacancy Text Generator crafts tailored job descriptions for Brunel roles, saving time for recruiters while ensuring clarity and accuracy.',
        asia: 'Kick-start the text for your vacancy using this Brunel-specific AI text generator. Enter a few words or several sentences and the generator will create a draft advertisement for you to review. This in-house tool, draws from existing Brunel ads for a consistent feel and even splits the generated texts into the fields required for Mercury/Wizard.',
        australasia: 'Kick-start the text for your vacancy using this Brunel-specific AI text generator. Enter a few words or several sentences and the generator will create a draft advertisement for you to review. This in-house tool, draws from existing Brunel ads for a consistent feel and even splits the generated texts into the fields required for Mercury/Wizard.',
        be: 'De AI Vacaturetekstgenerator maakt op maat gemaakte vacaturebeschrijvingen voor Brunel-functies, waardoor tijd wordt bespaard voor recruiters en tegelijkertijd duidelijkheid en nauwkeurigheid wordt gegarandeerd.',
        dachcz: 'The AI Vacancy Text Generator crafts tailored job descriptions for Brunel roles, saving time for recruiters while ensuring clarity and accuracy.',
        'europe-africa': 'The AI Vacancy Text Generator crafts tailored job descriptions for Brunel roles, saving time for recruiters while ensuring clarity and accuracy.',
        'middle-east-india': 'The AI Vacancy Text Generator crafts tailored job descriptions for Brunel roles, saving time for recruiters while ensuring clarity and accuracy.',
        nl: 'De AI Vacaturetekstgenerator maakt op maat gemaakte vacaturebeschrijvingen voor Brunel-functies, waardoor tijd wordt bespaard voor recruiters en tegelijkertijd duidelijkheid en nauwkeurigheid wordt gegarandeerd.'
      },
      link: 'https://openai.brunel.net/',
      imageSrc: '/images/cv-generator.png',
      showQRImg: [],
      logoSrc: '/images/openai-lockup.svg',
      logoAlt: 'OpenAI',
      category: 'Portal'
    },
    proposalGenerator: {
      title: {
        americas: 'Client Proposal Generator',
        asia: 'Candidate Summary Generator',
        australasia: 'Candidate Summary Generator',
        be: 'Klant voorstel generator',
        dachcz: 'Client Proposal Generator',
        'europe-africa': 'Client Proposal Generator',
        'middle-east-india': 'Client Proposal Generator',
        nl: 'Klant voorstel generator'
      },
      description: {
        americas: 'Impress potential clients with tailored proposals generated seamlessly through our platform. Customize templates, add your personal touch, and deliver compelling pitches in minutes.',
        asia: 'Create a short, sharp summary of your candidate and how they match your client’s requirements in a few easy steps. Simply copy and paste the candidate’s CV and the client’s Position Description or job specs into the tool and it will summarise the key reasons your candidate is perfect for the job!',
        australasia: 'Create a short, sharp summary of your candidate and how they match your client’s requirements in a few easy steps. Simply copy and paste the candidate’s CV and the client’s Position Description or job specs into the tool and it will summarise the key reasons your candidate is perfect for the job!',
        be: 'Imponeer potentiële klanten met op maat gemaakte voorstellen die naadloos worden gegenereerd via ons platform. Pas sjablonen aan, voeg je persoonlijke touch toe en lever overtuigende pitches binnen enkele minuten.',
        dachcz: 'Impress potential clients with tailored proposals generated seamlessly through our platform. Customize templates, add your personal touch, and deliver compelling pitches in minutes.',
        'europe-africa': 'Impress potential clients with tailored proposals generated seamlessly through our platform. Customize templates, add your personal touch, and deliver compelling pitches in minutes.',
        'middle-east-india': 'Impress potential clients with tailored proposals generated seamlessly through our platform. Customize templates, add your personal touch, and deliver compelling pitches in minutes.',
        nl: 'Imponeer potentiële klanten met op maat gemaakte voorstellen die naadloos worden gegenereerd via ons platform. Pas sjablonen aan, voeg je persoonlijke touch toe en lever overtuigende pitches binnen enkele minuten.'
      },
      link: 'https://ai.brunel.net/',
      imageSrc: '/images/proposal-generator.png',
      showQRImg: [],
      logoSrc: '/images/openai-lockup.svg',
      logoAlt: 'OpenAI',
      category: 'Portal'
    },
    HireAra: {
      title: {
        americas: 'Brunel CV  Generator',
        asia: 'Instant CV Formatting (HireAra)',
        australasia: 'Instant CV Formatting (HireAra)',
        be: 'Brunel CV  Generator',
        dachcz: 'Brunel CV  Generator',
        'europe-africa': 'Brunel CV  Generator',
        'middle-east-india': 'Brunel CV  Generator',
        nl: 'Brunel CV  Generator'
      },
      description: {
        americas: 'Specifically designed for Brunel members, this tool empowers you to create standout CVs optimized for the industrys standards and also highlighting the similarities with the candidate and the assignment.',
        asia: 'Convert your candidate’s CV into a Brunel or client-branded document in seconds! Upload > Download > Done! For more information visit the Instructions using the button above.',
        australasia: 'Convert your candidate’s CV into a Brunel or client-branded document in seconds! Upload > Download > Done! For more information visit the Instructions using the button above.',
        be: 'Speciaal ontworpen voor Brunel-leden, stelt deze tool je in staat om opvallende CV’s te maken die geoptimaliseerd zijn voor de normen van de industrie en ook de overeenkomsten tussen de kandidaat en de opdracht benadrukken.',
        dachcz: 'Specifically designed for Brunel members, this tool empowers you to create standout CVs optimized for the industrys standards and also highlighting the similarities with the candidate and the assignment.',
        'europe-africa': 'Specifically designed for Brunel members, this tool empowers you to create standout CVs optimized for the industrys standards and also highlighting the similarities with the candidate and the assignment.',
        'middle-east-india': 'Specifically designed for Brunel members, this tool empowers you to create standout CVs optimized for the industrys standards and also highlighting the similarities with the candidate and the assignment.',
        nl: 'Speciaal ontworpen voor Brunel-leden, stelt deze tool je in staat om opvallende CV’s te maken die geoptimaliseerd zijn voor de normen van de industrie en ook de overeenkomsten tussen de kandidaat en de opdracht benadrukken.'
      },
      link: 'https://www.present.hireara.ai/login',
      imageSrc: '/images/hireara.png',
      showQRImg: [],
      logoSrc: '/images/HireAraLogoPurple.png',
      logoAlt: 'HireAra',
      category: 'Portal'
    },
    RecruiterInsider: {
      title: {
        americas: 'Recruiter Insider',
        asia: 'Recruiter Insider',
        australasia: 'Recruiter Insider',
        be: 'Recruiter Insider',
        dachcz: 'Recruiter Insider',
        'europe-africa': 'Recruiter Insider',
        'middle-east-india': 'Recruiter Insider',
        nl: 'Recruiter Insider'
      },
      description: {
        americas: 'Gather insights and reviews from your candidates and clients across the hiring process.',
        asia: 'Gather insights and reviews from your candidates and clients across the hiring process.',
        australasia: 'Gather insights and reviews from your candidates and clients across the hiring process.',
        be: 'Gather insights and reviews from your candidates and clients across the hiring process.',
        dachcz: 'Gather insights and reviews from your candidates and clients across the hiring process.',
        'europe-africa': 'Gather insights and reviews from your candidates and clients across the hiring process.',
        'middle-east-india': 'Gather insights and reviews from your candidates and clients across the hiring process.',
        nl: 'Gather insights and reviews from your candidates and clients across the hiring process.'
      },
      link: 'https://login-eu.recruiterinsider.com/account/login',
      imageSrc: '/images/recruiterinsider.png',
      showQRImg: [],
      logoSrc: '/images/recruiter_insider.png',
      logoAlt: 'Recruiter Insider',
      category: 'Portal'
    },
    MyBrunelWeb: {
      title: {
        americas: '',
        asia: '',
        australasia: '',
        be: 'My Brunel Web',
        dachcz: '',
        'europe-africa': '',
        'middle-east-india': '',
        nl: 'My Brunel Web',
      },
      description: {
        americas: '',
        asia: '',
        australasia: '',
        be: 'Specialisten persoonlijke hub voor het beheren van alle aspecten van je professionele reis, van Topvoordelen tot het schrijven van uren voor projecten.',
        dachcz: '',
        'europe-africa': '',
        'middle-east-india': '',
        nl: 'Specialisten persoonlijke hub voor het beheren van alle aspecten van je professionele reis, van Topvoordelen tot het schrijven van uren voor projecten.'
      },
      link: 'https://my.brunel.net/',
      imageSrc: '/images/mybrunel.png',
      showQRImg: [],
      logoSrc: '/images/brunel_logo.png',
      logoAlt: 'My Brunel Web',
      category: 'Portal'
    },
    MyBruneliOS: {
      title: {
        americas: '',
        asia: '',
        australasia: '',
        be: 'My Brunel iOS',
        dachcz: '',
        'europe-africa': '',
        'middle-east-india': '',
        nl: 'My Brunel iOS',
      },
      description: {
        americas: '',
        asia: '',
        australasia: '',
        be: 'Blijf verbonden onderweg met onze intuïtieve mobiele applicaties, die volledige functionaliteit en gemak bieden binnen handbereik.',
        dachcz: '',
        'europe-africa': '',
        'middle-east-india': '',
        nl: 'Blijf verbonden onderweg met onze intuïtieve mobiele applicaties, die volledige functionaliteit en gemak bieden binnen handbereik.'
      },
      link: 'https://apps.apple.com/nl/app/mybrunel/id1622009800',
      imageSrc: '/images/App_Store_Badge.svg',
      showQRImg: ['be', 'nl'],
      imageSrcQr: '/images/mybrunel-ios.svg',
      logoSrc: '/images/app-icon.png',
      logoAlt: 'My Brunel iOS',
      category: 'Portal'
    },
    MyBrunelAndroid: {
      title: {
        americas: '',
        asia: '',
        australasia: '',
        be: 'My Brunel Android',
        dachcz: '',
        'europe-africa': '',
        'middle-east-india': '',
        nl: 'My Brunel Android',
      },
      description: {
        americas: '',
        asia: '',
        australasia: '',
        be: 'Blijf in contact, waar je ook bent, met onze gebruiksvriendelijke mobiele apps, die volledige functionaliteit en gemak bieden binnen handbereik.',
        dachcz: '',
        'europe-africa': '',
        'middle-east-india': '',
        nl: 'Blijf in contact, waar je ook bent, met onze gebruiksvriendelijke mobiele apps, die volledige functionaliteit en gemak bieden binnen handbereik.'
      },
      link: 'https://play.google.com/store/apps/details?id=net.brunel.app&hl=nl&gl=US&pli=1',
      imageSrc: '/images/Google_Play_Store_Badge.svg',
      showQRImg: ['be', 'nl'],
      imageSrcQr: '/images/mybrunel-android.svg',
      logoSrc: '/images/app-icon.png',
      logoAlt: 'My Brunel iOS',
      category: 'Portal'
    }/*,
     PowerBINPS: {
      title: {
        americas: 'PowerBI NPS Dashboard',
        asia: 'PowerBI NPS Dashboard',
        australasia: 'PowerBI NPS Dashboard',
        be: 'PowerBI NPS Dashboard',
        dachcz: 'PowerBI NPS Dashboard',
        'europe-africa': 'PowerBI NPS Dashboard',
        'middle-east-india': 'PowerBI NPS Dashboard',
        nl: 'PowerBI NPS Dashboard',
      },
      description: {
        americas: 'This NPS Dashboard provides a comprehensive analysis of customer satisfaction and loyalty, as measured by the Net Promoter Score (NPS). It visualizes the distribution of promoters, passives, and detractors, offering insights into customer experience trends and overall brand perception.',
        asia: 'This NPS Dashboard provides a comprehensive analysis of customer satisfaction and loyalty, as measured by the Net Promoter Score (NPS). It visualizes the distribution of promoters, passives, and detractors, offering insights into customer experience trends and overall brand perception.',
        australasia: 'This NPS Dashboard provides a comprehensive analysis of customer satisfaction and loyalty, as measured by the Net Promoter Score (NPS). It visualizes the distribution of promoters, passives, and detractors, offering insights into customer experience trends and overall brand perception.',
        be: 'Dit NPS Dashboard biedt een uitgebreide analyse van klanttevredenheid en loyaliteit, gemeten door de Net Promoter Score (NPS). Het visualiseert de verdeling van promotors, passieven en criticasters, en geeft inzicht in trends in de klantervaring en de algehele merkperceptie.',
        dachcz: 'This NPS Dashboard provides a comprehensive analysis of customer satisfaction and loyalty, as measured by the Net Promoter Score (NPS). It visualizes the distribution of promoters, passives, and detractors, offering insights into customer experience trends and overall brand perception.',
        'europe-africa': 'This NPS Dashboard provides a comprehensive analysis of customer satisfaction and loyalty, as measured by the Net Promoter Score (NPS). It visualizes the distribution of promoters, passives, and detractors, offering insights into customer experience trends and overall brand perception.',
        'middle-east-india': 'This NPS Dashboard provides a comprehensive analysis of customer satisfaction and loyalty, as measured by the Net Promoter Score (NPS). It visualizes the distribution of promoters, passives, and detractors, offering insights into customer experience trends and overall brand perception.',
        nl: 'Dit NPS Dashboard biedt een uitgebreide analyse van klanttevredenheid en loyaliteit, gemeten door de Net Promoter Score (NPS). Het visualiseert de verdeling van promotors, passieven en criticasters, en geeft inzicht in trends in de klantervaring en de algehele merkperceptie.'
      },
      link: 'https://app.powerbi.com/groups/me/apps/bd092b25-d0ae-4ef8-a743-5bc49572f6e7/reports/5c1f5987-15fe-427a-8d78-ee4ddd222651/ReportSection82f5c872a54f36c84742?ctid=3681f279-453b-44f0-8026-720f4cb38bda&experience=power-bi',
      imageSrc: '/images/powerbi-nps.png',
      showQRImg: [],
      imageSrcQr: '',
      logoSrc: '/images/brunel_logo.png',
      logoAlt: 'PowerBI NPS',
      category: 'PowerBI'
    } */
  }
  
  
  // Define region-app mapping
  export const regionApps: { [key: string]: string[] } = {
    americas: ['cvGenerator', 'proposalGenerator', 'HireAra'],
    asia: ['cvGenerator', 'proposalGenerator', 'HireAra'],
    australasia: ['cvGenerator', 'proposalGenerator', 'HireAra', 'RecruiterInsider'],
    be: ['MyBrunelWeb', 'MyBruneliOS', 'MyBrunelAndroid', 'cvGenerator', 'proposalGenerator', 'HireAra'],
    dachcz: ['cvGenerator', 'proposalGenerator', 'HireAra'],
    'europe-africa': ['cvGenerator', 'proposalGenerator', 'HireAra'],
    'middle-east-india': ['cvGenerator', 'proposalGenerator', 'HireAra'],
    nl: ['MyBrunelWeb', 'MyBruneliOS', 'MyBrunelAndroid', 'cvGenerator', 'proposalGenerator', 'HireAra',/*'PowerBINPS'*/]
  };